
import { Options, Vue } from 'vue-class-component';
import { HeadquartersClient } from '@/services/Services';
import EditHeadquarterModal from './modals/editHeadquarterModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class HeadquartersList extends Vue {

    headquartersList: OM.HeadquarterListVM[] = [];

    created() {
        this.init();
    }

    init() {
        HeadquartersClient.getAll()
        .then(x => {
            this.headquartersList = x;
            console.log(this.headquartersList)
        })
    }

    editHeadquarter(headquarter: OM.HeadquarterListVM) {
        if(headquarter == null)
            headquarter = new OM.HeadquarterListVM();

        this.$opModal.show(EditHeadquarterModal, {
            headquarter: headquarter,
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

}
