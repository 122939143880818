
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { HeadquartersClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class EditHeadquarterModal extends Vue {

    @Prop({
        default: () => new OM.HeadquarterListVM()
    }) headquarter: OM.HeadquarterListVM;

    @Prop() callback: any;

    headquarterName: string = "";
    localHeadquarterInfo : { [key: string]: string; } = {};

    created() {
        this.headquarterName = this.headquarter.headquarterName;

        if(this.headquarter.headquarterIdentifier && this.headquarter.headquarterInfo != null) {
            var propertyList = Object.keys(this.headquarter.headquarterInfo.properties);
            propertyList.forEach(element => {
                this.localHeadquarterInfo[element] = this.headquarter.headquarterInfo.properties[element];
            });
        }
    }

    get saveDisabled() {
        return !this.headquarterName;
    }

    save() {
        if(!this.headquarter.headquarterIdentifier) {
            HeadquartersClient.create(this.headquarterName)
            .then(x => {
                this.callback();
            })
        }
        else {
            var vm = new OM.ItemUpdatePropertiesVM();
            vm.itemIdentifier = this.headquarter.headquarterIdentifier;
            vm.propertiesToUpdate = this.localHeadquarterInfo;

            HeadquartersClient.updateManyProperties(vm)
            .then(x => {
                this.callback();
            })
        }
    }

}
